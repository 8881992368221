import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { getOloSchedual, getOloSettings } from '../common/parsers'
import moment from 'moment'
import { LocationScheduleDayState } from '../state/LocationSchedualState'
import {
   setIsLocationScheduleAvailable,
   setLocationScheduleToday,
} from '../../../services/store/LocationSchedual'
import { FormattedMessage, useIntl } from 'react-intl'
import { determineStoreStatusForTheDay } from '../common/utilities'

export const StoreClosedBanner = () => {
   const intl = useIntl()
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const [orderAlertShow, setOrderAlertShow] = useState<boolean>(false)
   const [nextScheduleDay, setNextScheduleDay] = useState<string | null>(null)
   const [nextScheduleDayOpenTime, setNextScheduleDayOpenTime] = useState<string | null>(null)
   const [openTill, setOpenTill] = useState<string>('')
   const [closeMessage, setCloseMessage] = useState<string>('')

   const dispatch = useDispatch<AppDispatch>()

   const OloSchedual = getOloSchedual(LocSettings)

   const currentDay = moment().locale('en').format('dddd').toLowerCase()
   const timeFormat = 'HH:mm:ss'
   var currentTimeString = moment().format(timeFormat)
   const oloSettings = getOloSettings(LocSettings)

   let nextDayDetails: LocationScheduleDayState | null = null

   const findSchedule = (currentDay: string) => {
      if (OloSchedual) {

         const dayDetails = determineStoreStatusForTheDay(LocSettings);
         if (dayDetails && dayDetails?.status == 1) {
            var beginningTime = moment(currentTimeString, timeFormat)
            const beforeTime = dayDetails?.start_time
            const afterTime = dayDetails?.end_time
            let momentAfter = moment(afterTime, timeFormat);
            let timeValidation = beginningTime.isBetween(
               moment(beforeTime, timeFormat),
               moment(afterTime, timeFormat)
            )
            if (dayDetails?.end_date) {
               momentAfter = moment(dayDetails.end_date);
               timeValidation = beginningTime.isBetween(moment(beforeTime, timeFormat), momentAfter);
            }
            const checkBeforeOpen = beginningTime.isBefore(moment(beforeTime, timeFormat))
            if (timeValidation) {
               const endTime = moment(afterTime, timeFormat);
               if (dayDetails?.end_date && !moment(dayDetails.end_date).isSame(moment(), "day")) {
                  setOpenTill(" ")
               } else
                  setOpenTill(endTime.format("hh:mm A"))
            }
            if (checkBeforeOpen || timeValidation) {
               dispatch(setIsLocationScheduleAvailable(true))
               dispatch(setLocationScheduleToday(true))
            }
            if (checkBeforeOpen) {
               setNextScheduleDay('today')
               setNextScheduleDayOpenTime(moment(beforeTime, timeFormat).format('hh:mm A'))
               dispatch(setIsLocationScheduleAvailable(true))
               setOrderAlertShow(true)
            } else if (!timeValidation) {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         } else {
            if (dayDetails && dayDetails?.status == 0 && dayDetails?.end_date) {
               setCloseMessage(dayDetails?.message ?? "");
               setOrderAlertShow(true)
            } else {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         }
      }
   }
   const findNextSchedule = (currentDay: string, daycount: number) => {
      dispatch(setLocationScheduleToday(false))

      if (daycount > 6) {
         dispatch(setIsLocationScheduleAvailable(false))
         return
      }

      const nextDay = moment().locale("en").add(daycount, 'days').format('dddd')
      if ((oloSettings?.schedule_orders_days ?? 1) < daycount) {
         dispatch(setIsLocationScheduleAvailable(false))
      }
      Object.entries(OloSchedual).map((day) => {
         if (day[0] == nextDay.toLowerCase()) {
            nextDayDetails = day[1]
         }
      })
      if (nextDayDetails && nextDayDetails.status == 1) {
         setNextScheduleDay(daycount == 1 ? "Tomorrow" : nextDay)
         setNextScheduleDayOpenTime(moment(nextDayDetails.start_time, timeFormat).format('hh:mm A'))
         setOrderAlertShow(true)
         if ((oloSettings?.schedule_orders_days ?? 1) >= daycount) {
            dispatch(setIsLocationScheduleAvailable(true))
         }
      } else {
         findNextSchedule(currentDay, daycount + 1)
      }
   }

   useEffect(() => {
      findSchedule(currentDay)
   }, [])

   return (
      <>{orderAlertShow && (
         <span className='text-danger fw-bold'>
            {nextScheduleDayOpenTime == null && nextScheduleDay == null ? (
               <>
                  {LocSettings.location.location_name}{' '}
                  <FormattedMessage
                     id='olo.store.currently_not_open'
                     defaultMessage='is currently not open'
                  />
                  <div>
                     {closeMessage}
                  </div>
               </>
            ) : (
               <>
                  <FormattedMessage
                     id='olo.store.will_open_at'
                     defaultMessage='is currently not open and will open at'
                  />{' '}
                  {nextScheduleDayOpenTime} {nextScheduleDay && nextScheduleDay != "today" && (
                     intl.formatMessage({ id: 'common.on' }) && ' ' && nextScheduleDay
                  )}
               </>
            )}
         </span>
      )}
         {!orderAlertShow && openTill == " " && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open'
                  defaultMessage='Open'
               />
            </span>
         )}
         {!orderAlertShow && openTill.trim() != "" && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open_till'
                  defaultMessage='Open till'
               />{' '} {openTill}
            </span>
         )}
      </>
   )
}
